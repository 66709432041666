import AddIcon from '@mui/icons-material/Add';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddSessionAbsenceDialog from 'components/AddSessionAbsenceDialog';
import DetailsSection from 'components/DetailsSection';
import { useDialog } from 'components/Dialog';
import SessionRegistrationDetailsAbsence from 'components/SessionRegistrationDetailsAbsence';

import { MySessionRegistrationDetails } from 'schema';

type Props = {
  registration: MySessionRegistrationDetails;
};

const SessionRegistrationDetailsAbsences = ({ registration }: Props) => {
  const { absences } = registration;
  const { openDialog: openAddDialog, ...addDialog } = useDialog();

  return (
    <DetailsSection noMargin>
      {absences.length > 0 ? (
        absences.map((absence, i) => (
          <>
            <SessionRegistrationDetailsAbsence
              registration={registration}
              absence={absence}
            />

            {i < absences.length - 1 && <Divider sx={{ mt: 1, mb: 1 }} />}
          </>
        ))
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ fontSize: 40 }}>
            <MedicalInformationIcon color="disabled" fontSize="inherit" />
          </Box>

          <Typography color="text.secondary">Nėra ligų</Typography>
        </Box>
      )}

      <Divider sx={{ mt: 1, mb: 2 }} />

      <Box sx={{ textAlign: 'right' }}>
        <LoadingButton startIcon={<AddIcon />} onClick={openAddDialog}>
          Įvesti ligą
        </LoadingButton>
      </Box>

      <AddSessionAbsenceDialog registration={registration} {...addDialog} />
    </DetailsSection>
  );
};

export default SessionRegistrationDetailsAbsences;
