import Box, { BoxProps } from '@mui/material/Box';
import { ReactNode } from 'react';

type Props = BoxProps & {
  children: ReactNode;
};

const ResponsiveDialogMobileContent = (props: Props) => {
  const { children, sx = [], ...rest } = props;

  return (
    <Box
      sx={[
        { bgcolor: '#f9fafb', minHeight: '100vh' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}>
      <Box sx={{ pt: 7, pb: 9 }}>{children}</Box>
    </Box>
  );
};

export default ResponsiveDialogMobileContent;
