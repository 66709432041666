import Skeleton from '@mui/material/Skeleton';
import BottomToolbar from 'components/BottomToolbar';
import ResponsiveDialogMobileContent from 'components/ResponsiveDialogMobileContent';
import ResponsiveDialogMobileTitle from 'components/ResponsiveDialogMobileTitle';

import { ResponsiveDialogMobileProps } from 'components/ResponsiveDialogMobile';

type Props = Omit<ResponsiveDialogMobileProps, 'open' | 'title'>;

const ResponsiveDialogMobileSkeleton = (props: Props) => {
  const { avatar, variant, children, actions, onClose } = props;

  return (
    <>
      <ResponsiveDialogMobileTitle
        onClose={onClose}
        variant={variant}
        avatar={avatar}>
        <Skeleton width="65%" />
      </ResponsiveDialogMobileTitle>

      <ResponsiveDialogMobileContent>{children}</ResponsiveDialogMobileContent>

      {actions && (
        <BottomToolbar>
          <Skeleton variant="rectangular" height={42} />
        </BottomToolbar>
      )}
    </>
  );
};

export default ResponsiveDialogMobileSkeleton;

