import DialogActions from '@mui/material/DialogActions';
import Skeleton from '@mui/material/Skeleton';
import ResponsiveDialogDesktopContent from 'components/ResponsiveDialogDesktopContent';
import ResponsiveDialogDesktopTitle from 'components/ResponsiveDialogDesktopTitle';

import { ResponsiveDialogDesktopProps } from 'components/ResponsiveDialogDesktop';

type Props = Omit<ResponsiveDialogDesktopProps, 'open' | 'title'>;

const ResponsiveDialogDesktopSkeleton = (props: Props) => {
  const { avatar, children, actions, onClose } = props;

  return (
    <>
      <ResponsiveDialogDesktopTitle avatar={avatar} onClose={onClose}>
        <Skeleton width="65%" />
      </ResponsiveDialogDesktopTitle>

      <ResponsiveDialogDesktopContent sx={{ pt: 2, pb: 2 }}>
        {children}
      </ResponsiveDialogDesktopContent>

      {actions && (
        <DialogActions>
          <Skeleton variant="rectangular" width={100} height={36} />
        </DialogActions>
      )}
    </>
  );
};

export default ResponsiveDialogDesktopSkeleton;
