import { useCallback } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import FormResponsiveDialog from 'components/FormResponsiveDialog';
import ResponsiveDialogButton from 'components/ResponsiveDialogButton';
import AttendandEditDialogFormSkeleton from 'components/AttendandEditDialogFormSkeleton';
import UserAvatar from 'components/UserAvatar';
import AvatarSkeleton from 'components/AvatarSkeleton';
import AttendantFormFields, {
  FormFields,
} from 'components/AttendantFormFields';

import { useAttendant, useUpdateAttendant } from 'features/Attendant';
import { AttendantListView } from 'schema';
import { DialogProps } from 'components/Dialog';

type Props = Omit<DialogProps, 'title' | 'children'> & {
  attendant: AttendantListView;
};

const AttendandEditDialog = ({ attendant, open, onClose, ...props }: Props) => {
  const update = useUpdateAttendant(attendant.id);
  const query = useAttendant(attendant.id, open);

  const handleSubmit = useCallback(
    ({ dateOfBirth, ...form }: FormFields) => {
      const values = {
        ...form,
        dateOfBirth: dateOfBirth!,
      };

      update.mutate(values, {
        onSuccess: () => {
          onClose();
        },
      });
    },
    [update, onClose]
  );

  return (
    <FormResponsiveDialog
      fullWidth
      open={open}
      maxWidth="sm"
      variant="back"
      title={query.data?.fullName}
      onClose={onClose}
      avatar={
        query.data ? <UserAvatar user={query.data} /> : <AvatarSkeleton />
      }
      form={{ initialValues: query.data, onSubmit: handleSubmit }}
      skeleton={<AttendandEditDialogFormSkeleton />}
      actions={
        <ResponsiveDialogButton
          type="submit"
          loading={update.isPending}
          endIcon={<SaveIcon />}>
          Saugoti
        </ResponsiveDialogButton>
      }
      {...props}>
      <AttendantFormFields />
    </FormResponsiveDialog>
  );
};

export default AttendandEditDialog;
