import PersonIcon from '@mui/icons-material/Person';
import AddAttendantDialog from 'components/AddAttendantDialog';
import AttendandsDialogContent from 'components/AttendandsDialogContent';
import ResponsiveDialog from 'components/ResponsiveDialog';
import ResponsiveDialogButton from 'components/ResponsiveDialogButton';

import { DialogProps, useDialog } from 'components/Dialog';

type Props = Omit<DialogProps, 'title' | 'children'>;

const AttendandsDialog = (props: Props) => {
  const { openDialog, ...dialog } = useDialog();

  return (
    <>
      <ResponsiveDialog
        fullWidth
        maxWidth="sm"
        title="Dalyviai"
        actions={
          <ResponsiveDialogButton endIcon={<PersonIcon />} onClick={openDialog}>
            Pridėti dalyvį
          </ResponsiveDialogButton>
        }
        {...props}>
        <AttendandsDialogContent />
      </ResponsiveDialog>

      <AddAttendantDialog {...dialog} />
    </>
  );
};

export default AttendandsDialog;
