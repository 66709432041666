import Stack from '@mui/material/Stack';
import TextFieldSkeleton from 'components/TextFieldSkeleton';

const AttendandEditDialogFormSkeleton = () => (
  <Stack spacing={4} sx={{ p: 2 }}>
    <TextFieldSkeleton width={100} />
    <TextFieldSkeleton width={110} />
    <TextFieldSkeleton width={150} />
  </Stack>
);

export default AttendandEditDialogFormSkeleton;
