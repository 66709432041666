import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import AttendantListItem from 'components/AttendantListItem';
import AttendantSkeleton from 'components/AttendantSkeleton';
import Blank from 'components/Blank';

import { useAttendants } from 'features/Attendant';

const AttendandsDialogContent = () => {
  const attendandsQuery = useAttendants();

  if (!attendandsQuery.data)
    return (
      <Box>
        <AttendantSkeleton />
        <AttendantSkeleton />
      </Box>
    );

  const attendands = attendandsQuery.data;

  return (
    <Box>
      {attendands.length > 0 ? (
        <List>
          {attendands.map(attendant => (
            <AttendantListItem key={attendant.id} attendant={attendant} />
          ))}
        </List>
      ) : (
        <Blank offset={128}>
          <PersonIcon fontSize="inherit" />
          <Typography>Dar nėra dalyvių</Typography>
        </Blank>
      )}
    </Box>
  );
};

export default AttendandsDialogContent;
