import { useCallback } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import FormResponsiveDialog from 'components/FormResponsiveDialog';
import ResponsiveDialogButton from 'components/ResponsiveDialogButton';
import AttendantFormFields, {
  FormFields,
  schema,
} from 'components/AttendantFormFields';

import { useCreateAttendant } from 'features/Attendant';
import { DialogProps } from 'components/Dialog';
import { useIsDesktop } from 'lib';

type Props = Omit<DialogProps, 'title' | 'children'>;

const initial: FormFields = {
  firstName: '',
  lastName: '',
  dateOfBirth: null,
};

const AddAttendantDialog = (props: Props) => {
  const create = useCreateAttendant();
  const isDesktop = useIsDesktop();

  const handleSubmit = useCallback(
    ({ dateOfBirth, ...values }: FormFields) => {
      const form = {
        ...values,
        dateOfBirth: dateOfBirth!,
      };

      create.mutate(form, {
        onSuccess: props.onClose,
      });
    },
    [create, props.onClose]
  );

  return (
    <FormResponsiveDialog
      fullWidth
      maxWidth="sm"
      variant="back"
      title="Pridėti dalyvį"
      form={{
        initialValues: initial,
        onSubmit: handleSubmit,
        validationSchema: schema,
      }}
      actions={
        <ResponsiveDialogButton
          type="submit"
          loading={create.isPending}
          endIcon={<PersonIcon />}>
          Pridėti
        </ResponsiveDialogButton>
      }
      {...props}>
      <Box sx={[!isDesktop && { minHeight: 'calc(100vh - 128px)', p: 2 }]}>
        <AttendantFormFields />
      </Box>
    </FormResponsiveDialog>
  );
};

export default AddAttendantDialog;
