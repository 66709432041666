import { Form, Formik, FormikConfig, FormikValues } from 'formik';

import MuiDialog from '@mui/material/Dialog';
import TransitionUp from 'components/TransitionUp';
import TransitionRight from 'components/TransitionRight';
import BottomToolbar from 'components/BottomToolbar';
import ResponsiveDialogMobileTitle from 'components/ResponsiveDialogMobileTitle';
import ResponsiveDialogMobileContent from 'components/ResponsiveDialogMobileContent';

import { ResponsiveDialogMobileProps } from 'components/ResponsiveDialogMobile';
import { ReactNode } from 'react';
import ResponsiveDialogMobileSkeleton from 'components/ResponsiveDialogMobileSkeleton';
import Box from '@mui/material/Box';

type DialogForm<F extends FormikValues> = Omit<
  FormikConfig<F>,
  'initialValues'
> & {
  initialValues?: F;
};

type Props<F extends FormikValues> = ResponsiveDialogMobileProps & {
  form: DialogForm<F>;
  skeleton?: ReactNode;
};

export type FormResponsiveDialogMobileProps<F extends FormikValues> = Props<F>;

function FormResponsiveDialogMobile<F extends FormikValues>(props: Props<F>) {
  const {
    onClose,
    title,
    children,
    variant = 'close',
    avatar,
    actions,
    skeleton,
    form,
    ...rest
  } = props;

  return (
    <MuiDialog
      fullScreen
      onClose={onClose}
      TransitionComponent={variant === 'close' ? TransitionUp : TransitionRight}
      {...rest}>
      {form.initialValues ? (
        <Formik<F> {...form} initialValues={form.initialValues}>
          <Form>
            <ResponsiveDialogMobileTitle
              onClose={onClose}
              variant={variant}
              avatar={avatar}>
              {title}
            </ResponsiveDialogMobileTitle>

            <ResponsiveDialogMobileContent>
              <Box sx={{ p: 2 }}>{children}</Box>
            </ResponsiveDialogMobileContent>

            {actions && <BottomToolbar>{actions}</BottomToolbar>}
          </Form>
        </Formik>
      ) : (
        <ResponsiveDialogMobileSkeleton
          onClose={onClose}
          avatar={avatar}
          actions={actions}>
          {skeleton}
        </ResponsiveDialogMobileSkeleton>
      )}
    </MuiDialog>
  );
}

export default FormResponsiveDialogMobile;
