import { useMe } from 'features/Auth';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  attendantCreate,
  attendantDelete,
  AttendantDetailsView,
  AttendantForm,
  attendantIndex,
  AttendantListView,
  attendantShow,
  AttendantShowInput,
  attendantUpdate,
} from 'schema';
import { useErrorHandler } from 'lib';
import { useNotification } from 'components/Notifications';

export const attendantKeys = {
  all: [{ scope: 'attendants' }] as const,

  lists: () => [{ ...attendantKeys.all[0], entity: 'list' }] as const,
  list: () => [{ ...attendantKeys.lists()[0] }] as const,

  details: () => [{ ...attendantKeys.all[0], entity: 'detail' }] as const,
  detail: (params: AttendantShowInput) =>
    [{ ...attendantKeys.details()[0], params }] as const,
};

type AttendantDetailsContext = QueryFunctionContext<
  ReturnType<(typeof attendantKeys)['detail']>
>;

export const useAttendants = () =>
  useQuery({
    queryKey: attendantKeys.list(),
    queryFn: attendantIndex,
  });

export const useAttendant = (attendant: number, enabled: boolean = true) =>
  useQuery({
    enabled,
    queryKey: attendantKeys.detail({ attendant }),
    queryFn: async ({ queryKey: [{ params }] }: AttendantDetailsContext) =>
      await attendantShow(params),
  });

export const useCreateAttendant = () => {
  const queryClient = useQueryClient();
  const queryKey = attendantKeys.list();
  const handleError = useErrorHandler();
  const me = useMe();

  return useMutation({
    mutationFn: (form: AttendantForm) => attendantCreate({ form }),
    onMutate: async form => {
      await queryClient.cancelQueries({ queryKey });

      const previousAttendants =
        queryClient.getQueryData<AttendantListView[]>(queryKey);

      queryClient.setQueryData<AttendantListView[]>(queryKey, old => [
        ...(old ?? []),
        {
          id: -1,
          ...form,
          fullName: `${form.firstName} ${form.lastName}`,
          user: me.data!,
        },
      ]);

      return { previousAttendants };
    },

    onError: (err: any, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousAttendants);
      handleError(err);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export const useUpdateAttendant = (attendant: number) => {
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: AttendantForm) => attendantUpdate({ attendant, form }),
    onError: useErrorHandler(),

    onSuccess: data => {
      client.setQueryData<AttendantDetailsView>(
        attendantKeys.detail({ attendant }),
        data
      );

      client.setQueryData<AttendantListView[]>(attendantKeys.list(), items =>
        (items ?? []).map(item => (item.id === data.id ? data : item))
      );

      client.invalidateQueries({
        queryKey: attendantKeys.detail({ attendant }),
      });

      client.invalidateQueries({
        queryKey: attendantKeys.list(),
      });

      pop('Išsaugota sėkmingai');
    },
  });
};

export const useDeleteAttendant = (attendant: number) => {
  const queryClient = useQueryClient();
  const queryKey = attendantKeys.list();
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => attendantDelete({ attendant }),

    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });

      const previousAttendants =
        queryClient.getQueryData<AttendantListView[]>(queryKey);

      queryClient.setQueryData<AttendantListView[]>(
        queryKey,
        attendants => attendants?.filter(item => item.id !== attendant) ?? []
      );

      return { previousAttendants };
    },

    onError: (err: any, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousAttendants);
      handleError(err);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
