import { ReactNode } from 'react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';

import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ResponsiveDialogDesktopTitle from 'components/ResponsiveDialogDesktopTitle';
import ResponsiveDialogDesktopContent from 'components/ResponsiveDialogDesktopContent';
import ResponsiveDialogDesktopSkeleton from 'components/ResponsiveDialogDesktopSkeleton';

import { ResponsiveDialogDesktopProps } from 'components/ResponsiveDialogDesktop';

type DialogForm<F extends FormikValues> = Omit<
  FormikConfig<F>,
  'initialValues'
> & {
  initialValues?: F;
};

type Props<F extends FormikValues> = ResponsiveDialogDesktopProps & {
  form: DialogForm<F>;
  skeleton?: ReactNode;
};

export type FormResponsiveDialogDesktopProps<F extends FormikValues> = Props<F>;

function FormResponsiveDialogDesktop<F extends FormikValues>(props: Props<F>) {
  const { onClose, title, avatar, children, skeleton, actions, form, ...rest } =
    props;
  const { initialValues, ...formik } = form;

  return (
    <MuiDialog onClose={onClose} scroll="body" {...rest}>
      {form.initialValues ? (
        <Formik<F> {...formik} initialValues={form.initialValues}>
          <Form>
            <ResponsiveDialogDesktopTitle avatar={avatar} onClose={onClose}>
              {title}
            </ResponsiveDialogDesktopTitle>

            <ResponsiveDialogDesktopContent>
              {children}
            </ResponsiveDialogDesktopContent>

            {actions && <DialogActions>{actions}</DialogActions>}
          </Form>
        </Formik>
      ) : (
        <ResponsiveDialogDesktopSkeleton
          onClose={onClose}
          avatar={avatar}
          actions={actions}>
          {skeleton}
        </ResponsiveDialogDesktopSkeleton>
      )}
    </MuiDialog>
  );
}

export default FormResponsiveDialogDesktop;
